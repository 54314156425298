import { defineStore } from 'pinia'
import { RemovableRef, useStorage } from '@vueuse/core'
import { postNow, prettyResponse } from '.';
import { AxiosResponse } from 'axios';
import { LoginResponse } from '@/models/Login';
import jwtDecode from 'jwt-decode';

// Can be removed if no use. Pinia will type it automatically with TypeScript anyway.
interface UserState {
  username: RemovableRef<string>; 
  id: RemovableRef<number>;
  authToken: RemovableRef<string>; 
  roles: RemovableRef<string[]>;
  expires: RemovableRef<number|undefined>;
  locale: RemovableRef<string>; 
  preAuthUrl: RemovableRef<string>; 
}

export type UserLogin = {
  username: string; 
  password: string}

export type ResetPasswordModel = {
  token: string;
  userName: string;
  password: string;
}

export type VerifyEmailModel = {
  token: string;
  userName: string;
}


export const useAuthStore = defineStore('auth', {
  state: ():UserState => ({
    username: useStorage("username", ""),
    id: useStorage("id", 0),
    authToken: useStorage("authToken", ""),
    roles: useStorage("roles",[]),
    expires: useStorage("expires",undefined),
    locale: useStorage("locale","fi-FI"),
    preAuthUrl: useStorage("preauthUrl",""),
  }),

  getters: {
    isAuthenticated: (state) => {
      return state.username !== "" && state.authToken !== "";
    },
    isMemberOf: (state) => (role: string) => {
      return state.roles.indexOf(role) > -1;
    },
  }, 

  actions: {
    logout() {
      this.username = "";
      this.id = 0;
      this.authToken = "";
      this.roles = [];
      this.expires = undefined;
    }, 

    checkAuth() {
      const noe = Date.now() / 1000;
      if(!this.expires) {
          // not authenticated
          return false;
      } else if(this.expires > noe){
          // ok
          return true;
      } else if (this.expires) {
          // expired
          this.logout();
          return false;
      }
      return false;
    },

    async login(username: string, password: string){
      try {
        const r = await postNow("Eport/UserApi/authenticate",{
          username:username, password:password}) as AxiosResponse<LoginResponse>;

          if(r.status === 200 && r.data.id && r.data.username && r.data.token){                
            const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
            const token = jwtDecode(r.data.token) as any;
            this.username = r.data.username;
            this.id = r.data.id;
            this.authToken = r.data.token;
            this.roles = token[roleClaim] ?? [];
            this.expires = token.exp;
            return;
          } else {
            throw (r as any).data.Message;
          }          

      } catch (error) {

        throw (prettyResponse(error,(message: any)=>{
          switch(message){
            case "Network Error":
              return "login.networkErr";
            case "Username or password is incorrect":
              return "login.userNameOrPasswordIsInvalidErr";
            default:
              return message;
          }
        }));

      }
    },

    async requestPasswordResetToken(username: string){
      try {
        const url = `${location.protocol}//${location.host}/resetWithToken`;
        const r = await postNow(`Eport/UserApi/PasswordResetEmail` ,{ username:username,resetUrl:url}) as AxiosResponse<boolean>;
        if(r.status !== 200)
          throw "error";
        
      } catch (error) {
        throw (prettyResponse(error,(message: any)=>{
          switch(message){
            case "Email not found":
              return "K�ytt�j�tunnusta tai s�hk�postia ei l�ytynyt!";
              default:
                return message;
          }
        }));
      }
    },

    async resetPasswordWithToken(token: string, username: string, password: string){
      try {
        const r = await postNow(`Eport/UserApi/ResetPasswordWithToken`,{
          password:password,
          token:token, 
          userName: username
        }) as AxiosResponse<LoginResponse>;

        if(r.status === 200 && r.data.id && r.data.username && r.data.token){
          const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
          const token = jwtDecode(r.data.token) as any;
          this.username = r.data.username;
          this.id = r.data.id;
          this.authToken = r.data.token;
          this.roles = token[roleClaim] ?? [];
          this.expires = token.exp;
          return;
        } else {
          throw (r as any).data;
         }

      } catch (error) {
        throw (prettyResponse(error,(message: any)=>{
          switch(message){
            case "userName is in use":
              return "K�ytt�j�tunnus tai s�hk�posti on jo k�yt�ss�.";
              default:
                return message;
          }
        }));
      }
    },

    async verifyEmailWithToken(token: string, username: string){
      try {        
        await postNow(`Eport/UserApi/VerifyEmailWithToken`,{
          token:token, 
          userName: username,
        }) as AxiosResponse<LoginResponse>;

      } catch (error) {
        throw(prettyResponse(error,(message)=>{
          switch(message){
            case "Invalid token":
              return "register.invalidTokenErr";
            default:
              return message;
          }
        }));
      }
    },

    async requestEmailVerifyToken(username: string){
      const verifyEmailUrl = `${location.protocol}//${location.host}/verifyEmail`;

      try {        
        await postNow(`Eport/UserApi/RequestEmailVerifyToken`,{
          userName: username,
          verifyEmailUrl: verifyEmailUrl,
        }) as AxiosResponse<LoginResponse>;

      } catch (error) {
        throw(prettyResponse(error,(message)=>{
          switch(message){
            case "Invalid token":
              return "register.invalidTokenErr";
            default:
              return message;
          }
        }));
      }
    },

    async register(username: string, email: string, password: string, phone: string){
      const verifyEmailUrl = `${location.protocol}//${location.host}/verifyEmail`;
      try {
        const r = await postNow(`Eport/UserApi/register`, { 
          username:username, email:email, password:password, verifyEmailUrl, phone:phone
        }) as AxiosResponse<LoginResponse>;
        
        if(r.status === 200&& r.data.id && r.data.username && r.data.token){
          const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
          const token = jwtDecode(r.data.token) as any;
          this.username = r.data.username;
          this.id = r.data.id;
          this.authToken = r.data.token;
          this.roles = token[roleClaim] ?? [];
          this.expires = token.exp;
          return;
        } else { 
          throw (r as any).data.Message;
        }         
      } catch (error) {
        throw(prettyResponse(error,(message)=>{
          switch(message){
            case "[passwordTooShort]":
              return "register.passwdShortErr";
            case "[invalidEmail]":
              return "register.invalidEmailErr";
            case "[userAlreadyExists]":
              return "register.emailInUseErr";
            case "Failed to create user!":
              return "register.emailInUseErr";
              default:
                return message;
          }
        }));
      }

    }

  }
  // other options...
})