import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.auth.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 0,
          onClick: _ctx.login
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.logInOutline }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("loginButton")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.auth.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 1,
          onClick: _ctx.logout
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.logOutOutline }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("logoutButton")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}