import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa60e3c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginStatus = _resolveComponent("LoginStatus")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_LoginStatus)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("team.signupEventList.title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "login-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "content-grid" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, { color: "primary" }, {
                        default: _withCtx(() => [
                          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t("team.signupEventList.title")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.signUpEventList, (signupevent) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: signupevent.code
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_IonLabel, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(signupevent.name), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (!_ctx.processing)
                                      ? (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 0,
                                          color: "secondary",
                                          class: "login-btn",
                                          onClick: ($event: any) => (_ctx.router.replace('/signups/'+signupevent.code)),
                                          expand: "block"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, { icon: _ctx.peopleOutline }, null, 8, ["icon"]),
                                            _createTextVNode(" " + _toDisplayString(_ctx.t("team.signupEventList.openButton")), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}