
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
  IonCol,
  IonRow,
  IonGrid,
loadingController,
  // handleAlert,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { logInOutline, batteryFullOutline, personAddOutline, } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "RegisterView",
  data() {
    return {
      loginInfo: { username: "", phone: "", password: "", password2: "" },
    };
  },
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
    IonCol,
    IonRow,
    IonGrid,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    const { showError } = useErrorBox();

    const { t, n, te } = useI18n<{ message: MessageSchema; number: NumberSchema }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const loading = async () => {
      const loading = await loadingController.create({
        message: t("processing"),
      });

      await loading.present();
      return loading;
    };
    
    const registerNow = async (p: any) => {    
      if (p.password != p.password2) {
        showError(t("register.checkPasswdErr"), t("global.Error"));
        return;
      }

      if (p.password.length < 6) {
        showError(t("register.passwdShortErr"), t("global.Error"));
        return;
      }

      const l = await loading();
      try {
        await auth.register(p.username,p.username,p.password, p.phone);

        if(auth.preAuthUrl)
          router.replace(auth.preAuthUrl);
        else
          router.replace(`${process.env.VUE_APP_HOME_URL}`);
        auth.preAuthUrl = "";
      } catch (err) {
        if(te(err as string))
          showError(t(err as string), t("global.Error"));
        else
          showError(err as string, t("global.Error"));
      } finally {
        l.dismiss();
      }
    };

    return {
      router,
      registerNow,
      logInOutline,
      batteryFullOutline,
      personAddOutline,
      t,
      n,
    };
  },
});
