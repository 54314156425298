
import { IonButton, IonIcon } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { logOutOutline, logInOutline } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "LoginStatus",
  data() {
    return {
      loginInfo: { username: "", password: "" },
    };
  },
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();

    const { t, n, availableLocales, locale, te } = useI18n<{ message: MessageSchema; number: NumberSchema; }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const login = async () => {
      router.replace({ name: 'Login' });
    };

    const logout = async () => {
      await auth.logout();

      if(router.currentRoute.value.meta.requiresAuth)
        router.replace({ name: 'Login' });
      else
        location.reload();
    };


    return {
      router,
      login, logout,
      logInOutline,logOutOutline,
      t,
      n,
      auth,
    };
  },
});
