
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  loadingController,
IonLabel,
IonText,  
IonToggle,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { personOutline, personAddOutline, logInOutline, cartOutline, syncOutline, newspaperOutline } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { SignUpViewModel } from "@/models/teamSignUp/Instances";
import { useTeamStore } from "@/store/teamStore";
import { useAuthStore } from "@/store/authStore";
import { useIonicValidate } from "@/utils/ionicValidate";
import * as moment from 'moment-timezone';

export default defineComponent({
  name: "SignUpDetails",
  data() {
    return {
      loginInfo: { username: "", password: "" },
    };
  },
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonTextarea,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonIcon,
  },
  setup() {
    const router = useRouter();    
    const teamStore = useTeamStore();
    const auth = useAuthStore();
    const momentjs: any = moment;
    momentjs.locale(auth.locale);

    const invoiceLink: Ref<string|undefined> = ref(undefined);
    
    const { t, n, availableLocales, locale, } = useI18n<{ message: MessageSchema; number: NumberSchema; }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const {validateIonInput} = useIonicValidate();
    const contactPhone: Ref<null|typeof IonInput> = ref(null);
    const contactName: Ref<null|typeof IonInput> = ref(null);
    const name: Ref<null|typeof IonInput> = ref(null);

    const signUp: Ref<SignUpViewModel|undefined> = ref(undefined);
    const { showError } = useErrorBox();

    const loading = async () => {
      const loading = await loadingController.create({
        message: t("processing"),
      });

      await loading.present();
      return loading;
    };

    onMounted(async () => {
      locale.value = auth.locale as any;
      const l = await loading();
      try {
        const id = Number.parseInt(router.currentRoute.value.params["id"].toString());
        const code = router.currentRoute.value.params["code"].toString();
        signUp.value = await teamStore.getSignUp(id, code);
      } catch(e){
        showError(e as string, t("team.signupdetails.errorLoading"));
      } finally {
        l.dismiss();
      }
    });

    // Validate the form by single element or all
    const validate = (ev: CustomEvent|undefined): boolean=> {
      let valid = true;

      // debugger;
      valid = validateIonInput(ev, contactPhone, (val)=>(val?.length ?? 0) > 5, t('team.signupdetails.contactPhoneRequired')) && valid;
      valid = validateIonInput(ev, contactName, (val)=>(val?.length ?? 0) > 5, t('team.signupdetails.contactNameRequired')) && valid;
      valid = validateIonInput(ev, name, (val)=>(val?.length ?? 0) > 5, t('team.signupdetails.contactPhoneRequired')) && valid;
      
      return valid;
    }

    // Save the signup
    const save = async()=> {
      if(signUp.value == undefined) 
        return;
      if(!validate(undefined)){
        showError(t("team.signupdetails.errorFillRequired"), t("team.signupdetails.errorSaving"));
        return;
      }

        // debugger;
      const l = await loading();
      try {
        signUp.value = await teamStore.updateSignUp( signUp.value );
      } catch(e){
        showError(t("team.signupdetails." + e as string), t("team.signupdetails.errorSaving"));
      } finally {
        l.dismiss();
      }
    }

    const back = async()=> {
      router.back();

    }

    const cancelSignup = async()=> {
      if(signUp.value == undefined) 
        return;
      signUp.value.state = "Cancelled";
      //router.back();
    }

    const getInvoice = async()=> {
      if(!signUp.value)
        return;
      const l = await loading();
      invoiceLink.value = undefined;

      try {
        const invoiceBlob =  await teamStore.getInvoicePdf(signUp.value.id, signUp.value.eventCode);
        const fileUrl = window.URL.createObjectURL(invoiceBlob);
        invoiceLink.value = fileUrl;
      } catch(e) {
        showError(e as string, t("team.signupdetails.errorLoadingInvoice"));
      } finally {
        l.dismiss();
      }
    }

    const isLocked = computed (()=> {
      if(!signUp.value)
        return false;
      return signUp.value.state == "Cancelled";
    });

    return {
      router,
      logInOutline,
      personOutline,
      personAddOutline,
      cartOutline,
      syncOutline,
      newspaperOutline,
      t,
      n,
      availableLocales,
      locale,
      signUp,
      save, back,cancelSignup,getInvoice,
      invoiceLink,
      momentjs,
      isLocked,
      validate,
      contactPhone, contactName, name,
    };
  },
});
