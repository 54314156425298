
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  loadingController,  
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { personOutline, personAddOutline, logInOutline, pencilOutline, syncOutline, } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { SignUpEventDto, SignUpListItem } from "@/models/teamSignUp/Instances";
import { useTeamStore } from "@/store/teamStore";
import { useAuthStore } from "@/store/authStore";
import LoginStatus from "@/views/auth/LoginStatus.vue";

export default defineComponent({
  name: "SignUpList",
  data() {
    return {
      loginInfo: { username: "", password: "" },
    };
  },
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonLabel,
    IonGrid,
    IonText,
    IonRow,
    IonCol,
    LoginStatus,
  },
  setup() {
    const router = useRouter();
    const teamStore = useTeamStore();
    const auth = useAuthStore();

    const { t, n, availableLocales, locale, } = useI18n<{ message: MessageSchema; number: NumberSchema; }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const signUpList: Ref<Record<string,SignUpListItem[]>|undefined> = ref(undefined);
    const signUpEvent: Ref<SignUpEventDto|undefined> = ref(undefined);

    const isAdmin: Ref<boolean> = ref(false);

    const myTeams: Ref<SignUpListItem[]> = ref([]);

    const { showError } = useErrorBox();

    const loading = async () => {
      const loading = await loadingController.create({
        message: t("processing"),
      });

      await loading.present();
      return loading;
    };

    onMounted(async () => {

      isAdmin.value = auth.isMemberOf("Admin");

      const l = await loading();
      try {
        locale.value = auth.locale as any;
        const code = router.currentRoute.value.params["code"].toString();
        const list = await teamStore.getAllSignUps(code);

        myTeams.value = list.filter((i)=>i.memberId == auth.id && i.memberId !== 0);

        signUpList.value = list
          .filter((i)=>i.state !== 'Cancelled')
          .reduce((group, item)=>{
            const { serieName } = item;
            group[serieName] = group[serieName] || [];
            group[serieName].push(item);
            return group;
          }, {} as Record<string, SignUpListItem[]>);
        
        signUpEvent.value = await teamStore.getSignUpEvent(code);
      } catch (e) {
        showError(e as string, t("global.Error"));
      } finally {
        l.dismiss();
      }
    });

    return {
      router,
      logInOutline,
      personOutline,
      personAddOutline,
      pencilOutline,
      syncOutline,
      t,
      n,
      availableLocales,
      locale,
      signUpEventList: signUpList,
      signUpEvent,
      myTeams,
      isAdmin,
    };
  },
});
