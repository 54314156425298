import { IonInput } from '@ionic/vue';
import { Ref } from 'vue';


/**
 * Initialize the validator for Ionic components
 * @returns validate functions
 */
export function useIonicValidate() {
  /**
   * Validate a single input and mark it as valid or invalid, return true if the input is valid
   * @param ev Provide the event that triggered the validation, or undefined to validate all inputs
   * @param ref Provide a reference to the IonInput element
   * @param isValid Provide a function to validate the input
   * @param errorMessage Provide an error message to show when the input is invalid
   * @returns true if the input is valid
   */
  const validateIonInput = (
      ev: CustomEvent|undefined, 
      ref: Ref<null|typeof IonInput>, 
      isValid: (val:string|undefined) => boolean , 
      errorMessage?: string)=> {

      // Get the element
      const el = ref.value?.$el;
      if(!el) return true;

      const cl = el.classList as DOMTokenList;

      let valid = true;
      // Mark as valid or invalid
      cl.remove("ion-valid", "ion-invalid");
      if(isValid(el.value)) {
        valid = true;
        cl.add("ion-valid");
      } else {
        valid = false; 
        cl.add("ion-invalid");
        if(errorMessage) 
          el.errorText = errorMessage;
      }

      // Mark as touched if same element or "all elements"
      if(!ev || ev.target === el) {
        cl.add("ion-touched");
        // return false;
      }

      return valid;
    }
  return { validateIonInput };
}
