
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from './store/authStore';

export default defineComponent({
  name: 'App',
  components: { 
    IonApp,
    IonRouterOutlet, 
  },
  setup() {
    const router = useRouter();

    const isAuthNeeded = () => {
      return !!router.currentRoute.value.meta.requiresAuth;
    }

    const visibilityChanged = async() => {
      if (document.hidden)
        return;
              
      const auth = useAuthStore();
      const a = await auth.checkAuth();
      if(a) {
        return;
      }

      if(!isAuthNeeded()){
        return;
      }

      auth.preAuthUrl = router.currentRoute.value.fullPath;
      router.replace({ name: 'Login' });
    }

    document.addEventListener("visibilitychange",visibilityChanged);
    return {};
  },
});

