import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import ResetWithToken from '@/views/auth/ResetWithToken.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import VerifyEmailWithToken from '@/views/auth/VerifyEmail.vue';

import SignUpsMain from '@/views/team/SignUps.vue';
import SignUpList from '@/views/team/SignUpList.vue';
import SignUpDetails from '@/views/team/SignUpDetails.vue';
import { useAuthStore } from '@/store/authStore';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/events'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/resetWithToken',
    name: 'ResetWithToken',
    component: ResetWithToken
  },
  {
    path: '/verifyEmail',
    name: 'VerifyEmailWithToken',
    component: VerifyEmailWithToken
  },
  {
    path: '/events',
    name: 'SignUpsMain',
    component: SignUpsMain,
    meta: { requiresAuth: false },
  },
  {
    path: '/signups/:code',
    name: 'SignUpList',
    component: SignUpList,
    meta: { requiresAuth: false },
  },
  {
    path: '/signup/:code/:id',
    name: 'SignUpDetails',
    component: SignUpDetails,
    meta: { requiresAuth: true },
  }, 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  
  // Redirect to login page if not authenticated and trying to access a restricted page
  if (to.meta.requiresAuth && !auth.isAuthenticated) {
    auth.preAuthUrl = to.fullPath;
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router
