
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
  IonGrid,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { logInOutline, mailOutline,} from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "VerifyEmailWithToken",
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    const {showError} = useErrorBox();
    const processing: Ref<boolean> = ref(false);
    const token: Ref<string> = ref("");
    const userName: Ref<string> = ref("");

    const { t, n, locale } = useI18n<{ message: MessageSchema; number: NumberSchema }>({
      useScope: "global",
    });
    
    onMounted(() => {
      locale.value = auth.locale as any;
      const parameters = router.currentRoute.value.query;
      if(parameters.token){
        token.value = parameters.token.toString();
      }
      if(parameters.user){
        userName.value = parameters.user.toString();
      }
    });

    const resetNow = async () => {
      try {
        if(token.value.length < 6 ){
          showError(t("verifyEmail.tokenIsRequiredErr"), t("global.Error"));
          return;
        }

        await auth.verifyEmailWithToken(token.value, userName.value);

        router.replace("/");
      } catch (err) {
        showError(err as string, t("global.Error"));
      }
    };

    const requestToken = async () => {
      try {

        await auth.requestEmailVerifyToken(userName.value);        
      } catch (err) {
        showError(err as string, t("global.Error"));
      }
    };

    const localeChanged = async () => {
      auth.locale = locale.value;
    };

    return {
      router,
      resetNow,requestToken,
      logInOutline,mailOutline,
      token,userName,
      processing,
      localeChanged,locale,
      t,n,
    };
  },
});
