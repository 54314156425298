import axios, { AxiosError } from "axios"
import { useAuthStore } from "./authStore";


export const prettyResponse = (reason: any, parseError: undefined | ((message: string) => string)) => {
  const errorResponse = reason as AxiosError;
  if (!errorResponse.isAxiosError) {
    return reason;
  }

  let responseData: any = errorResponse.response?.data;

  // Network errors or no response
  if (!responseData || responseData.length === 0) {
    if (parseError)
      return parseError(errorResponse.message);

    return (errorResponse.message);
  }




  if (responseData?.type === "https://tools.ietf.org/html/rfc7231#section-6.5.1") {
    responseData = responseData.title + ": " + JSON.stringify(responseData.errors);
  }
  if (responseData?.message) { responseData = responseData.message; }

  if (parseError)
    return parseError(responseData as string);

  return (responseData ?? "");

}

export function getDomain() {
  // if(location.hostname == "oma.poltesali.fi") 
  //   return `${process.env.VUE_APP_POLTE_SERVER_ADDRESS}`;

  return `${process.env.VUE_APP_SERVER_ADDRESS}`
}


export const postNow = (url: string, data: any) => {
  const auth = useAuthStore();
  return axios.post(`${getDomain()}/${url}`, data,
    {
      headers: {
        Authorization: 'Bearer ' + auth.authToken,
        Locale: auth.locale ?? "en",
        "Accept-Language": auth.locale ?? "en"
      }
    });
}

export const getNow = (url: string, params: URLSearchParams | undefined) => {
  const auth = useAuthStore();
  return axios.get(`${getDomain()}/${url}`,
    {
      headers: {
        Authorization: 'Bearer ' + auth.authToken,
        Locale: auth.locale ?? "en",
        "Accept-Language": auth.locale ?? "en"
      },
      params
    });
}



const logEmitter = (_message: string) => {
  //   logger.emit({
  //     timestamp: new Date(),
  //     level: 'Information',
  //     messageTemplate: message + 'by {user}!',
  //     properties: {
  //         user: store?.state?.user?.username
  //     }
  // });
}

export function useLogger() {
  return logEmitter;
}

class CurrentSessionObject {
  enteredRoute: string | undefined;
}

const currentSession = new CurrentSessionObject();

export function useSessionObject() {
  return currentSession as CurrentSessionObject;
}