
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  loadingController,  
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { logOutOutline, peopleOutline, } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { SignUpEventDto } from "@/models/teamSignUp/Instances";
import { useTeamStore } from "@/store/teamStore";
import { useAuthStore } from "@/store/authStore";

import LoginStatus from "@/views/auth/LoginStatus.vue";

export default defineComponent({
  name: "SignUpsMain",
  components: {
    // IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonText,
    IonLabel,
    // IonInput,
    IonGrid,
    IonRow,
    IonCol,
    // IonSelect,
    LoginStatus,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    const teamStore = useTeamStore();

    const { t, n, availableLocales, locale } = useI18n<{ message: MessageSchema; number: NumberSchema; }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const signUpEventList: Ref<Array<SignUpEventDto>> = ref([]);
    const processing: Ref<boolean> = ref(false);
    const { showError } = useErrorBox();


    const pageContent: Ref<object|undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "Hetki...",
        duration: undefined,
      });

      await loading.present();
      return loading;
    };

    const logOut = async () => {
      const l = await loading();
      try {
        await auth.logout();
        router.replace({ name: 'Login' });
      } catch (e) {
        showError(e as string, t("global.Error"));
      } finally {
        await l.dismiss();
      }
    };

    onMounted(async () => {
      const l = auth.locale;
      if (l) {
        locale.value = l as any;
      }

      signUpEventList.value = await teamStore.getAllOpenSignUpEvents();

    });

    return {
      router,
      processing,
      t,
      n,
      availableLocales,
      locale,
      pageContent,
      signUpEventList,
      logOutOutline,peopleOutline,
      logOut,
    };
  },
});
