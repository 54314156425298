import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d2e24bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_LoginStatus = _resolveComponent("LoginStatus")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_LoginStatus)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("team.signupevent.title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_ctx.signUpEvent)
            ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-lg": "4",
                        "size-xl": "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, { color: "primary" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h1", null, _toDisplayString(_ctx.signUpEvent.name), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("h2", null, _toDisplayString(), 1),
                          _createElementVNode("span", {
                            innerHTML: _ctx.signUpEvent?.description
                          }, null, 8, _hoisted_1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "7",
                        "size-lg": "4",
                        "size-xl": "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_text, { color: "primary" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.t("team.signupevent.yourTeams")), 1)
                                ]),
                                _: 1
                              }),
                              (_ctx.signUpEvent.isOpen)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 0,
                                    slot: "end",
                                    color: "primary",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push(`/signUp/${_ctx.signUpEvent.code}/0`)))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.personAddOutline }, null, 8, ["icon"]),
                                      _createTextVNode(" " + _toDisplayString(_ctx.t("team.signupevent.new")), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myTeams, (team) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  key: team.id,
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_text, {
                                      color: team.state=='Cancelled' ? 'danger' : 'primary'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(team.name) + " " + _toDisplayString(team.state=='Cancelled' ? _ctx.t("team.signupEventList.cancelled") : ''), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["color"]),
                                    (_ctx.signUpEvent.isOpen)
                                      ? (_openBlock(), _createBlock(_component_ion_button, {
                                          key: 0,
                                          slot: "end",
                                          color: "secondary",
                                          onClick: ($event: any) => (_ctx.router.push(`/signUp/${team.eventCode}/${team.id}`))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, { icon: _ctx.pencilOutline }, null, 8, ["icon"]),
                                            _createTextVNode(" " + _toDisplayString(_ctx.t("team.signupevent.edit")), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.signUpEventList)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            "size-xs": "12",
                            "size-md": "5",
                            "size-lg": "4",
                            "size-xl": "3"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_text, { color: "primary" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_title, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h2", null, _toDisplayString(_ctx.t("team.signupevent.teams")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.signUpEventList), (group) => {
                                return (_openBlock(), _createBlock(_component_ion_list, {
                                  key: group[0],
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(group[0]), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group[1], (signupevent) => {
                                      return (_openBlock(), _createBlock(_component_ion_item, {
                                        key: signupevent.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_text, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(signupevent.name), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          (_ctx.isAdmin)
                                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 0,
                                                slot: "end",
                                                color: "secondary",
                                                onClick: ($event: any) => (_ctx.router.push(`/signUp/${signupevent.eventCode}/${signupevent.id}`))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, { icon: _ctx.pencilOutline }, null, 8, ["icon"]),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.t("team.signupevent.edit")), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}