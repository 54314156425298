import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01b63a80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("team.signupdetails.title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "login-content" }, {
        default: _withCtx(() => [
          (_ctx.signUp)
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 0,
                class: "content-grid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, { color: "primary" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t("team.signupdetails.title")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    ref: "name",
                                    onIonInput: _ctx.validate,
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.name,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.signUp.name) = $event)),
                                    label: _ctx.t('team.signupdetails.name'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["onIonInput", "disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_select, {
                                    disabled: _ctx.isLocked,
                                    placeholder: _ctx.t('team.signupdetails.selectSeries'),
                                    label: _ctx.t('team.signupdetails.series'),
                                    "label-placement": "stacked",
                                    modelValue: _ctx.signUp.serieId,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signUp.serieId) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.signUp.series, (item) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: item.value,
                                          value: item.value
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.text), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["disabled", "placeholder", "label", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    ref: "contactName",
                                    onIonInput: _ctx.validate,
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.contactName,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.signUp.contactName) = $event)),
                                    label: _ctx.t('team.signupdetails.contactName'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["onIonInput", "disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.contactEmail,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.signUp.contactEmail) = $event)),
                                    label: _ctx.t('team.signupdetails.contactEmail'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    ref: "contactPhone",
                                    onIonInput: _ctx.validate,
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.contactPhone,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.signUp.contactPhone) = $event)),
                                    label: _ctx.t('team.signupdetails.contactPhone'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["onIonInput", "disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.street,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.signUp.street) = $event)),
                                    label: _ctx.t('team.signupdetails.street'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.zip,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.signUp.zip) = $event)),
                                    label: _ctx.t('team.signupdetails.zip'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.city,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.signUp.city) = $event)),
                                    label: _ctx.t('team.signupdetails.city'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.country,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.signUp.country) = $event)),
                                    label: _ctx.t('team.signupdetails.country'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_textarea, {
                                    disabled: _ctx.isLocked,
                                    style: {"height":"130px"},
                                    modelValue: _ctx.signUp.comments,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.signUp.comments) = $event)),
                                    label: _ctx.t('team.signupdetails.comments'),
                                    "label-placement": "stacked"
                                  }, null, 8, ["disabled", "modelValue", "label"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_toggle, {
                                    disabled: _ctx.isLocked,
                                    modelValue: _ctx.signUp.sendEmail,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.signUp.sendEmail) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('team.signupdetails.sendEmailSaving')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled", "modelValue"])
                                ]),
                                _: 1
                              }),
                              (_ctx.signUp.id>0)
                                ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(_ctx.t('team.signupdetails.created')) + " " + _toDisplayString(_ctx.momentjs(_ctx.signUp.createTime).calendar()), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, _toDisplayString(_ctx.t('team.signupdetails.invoiceDueDate')) + " " + _toDisplayString(_ctx.momentjs(_ctx.signUp.dueDate).calendar()), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("team.signupdetails.passProducts")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.signUp.passes.filter(i=>!i.additional), (item) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  key: item.id
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h2", null, _toDisplayString(item.productName) + " " + _toDisplayString(item.cost.toFixed(2)) + "€", 1),
                                        _createVNode(_component_ion_input, {
                                          disabled: _ctx.isLocked,
                                          inputmode: "numeric",
                                          modelValue: item.quantity,
                                          "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                                          "label-placement": "end",
                                          label: "kpl"
                                        }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("team.signupdetails.additionalProducts")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.signUp.passes.filter(i=>i.additional), (item) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  key: item.id
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h2", null, _toDisplayString(item.productName) + " " + _toDisplayString(item.cost.toFixed(2)) + "€", 1),
                                        _createVNode(_component_ion_input, {
                                          disabled: _ctx.isLocked,
                                          inputmode: "numeric",
                                          modelValue: item.quantity,
                                          "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                                          "label-placement": "end",
                                          label: "kpl"
                                        }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            color: "primary",
                            onClick: _ctx.save
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("saveButton")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_ion_button, {
                            color: "secondary",
                            onClick: _ctx.back
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("backButton")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          (_ctx.signUp.id !== 0 && !_ctx.isLocked)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 0,
                                color: "danger",
                                onClick: _ctx.cancelSignup
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("team.signupdetails.cancelSignup")), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          (!_ctx.invoiceLink && _ctx.signUp.id !== 0 && !_ctx.isLocked)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                color: "secondary",
                                onClick: _ctx.getInvoice
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("team.signupdetails.loadInvoice")), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.invoiceLink)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 2,
                                href: _ctx.invoiceLink,
                                download: "invoice.pdf"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: _ctx.newspaperOutline }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.t("team.signupdetails.showInvoice")), 1)
                                ]),
                                _: 1
                              }, 8, ["href"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}