import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { SignUpEventDto, SignUpListItem, SignUpViewModel } from '@/models/teamSignUp/Instances';
import { getDomain, getNow, postNow, prettyResponse } from '.';
import axios, { AxiosResponse } from 'axios';
import { useAuthStore } from './authStore';


export const useTeamStore = defineStore('team', {
    state: () => ({
            username: useStorage("username", ""),
            id: useStorage("id", 0),
            authToken: useStorage("authToken", ""),
            roles: useStorage("roles",[]),
            expires: useStorage("expires",undefined),
        }),

        getters: {
            isAuthenticated: (state) => {
                return state.username !== "" && state.authToken !== "";
              },
        }, 
        actions: {
            async updateSignUp (model: SignUpViewModel) {
                try {                                        
                    const r = await postNow(`Team/SignUpApi/PostSave/`,model) as AxiosResponse<SignUpViewModel>;
                    return r.data;
    
                } catch (error) {
                    throw(prettyResponse(error,undefined));
                }
            },

            async getSignUp (id: number, eventCode: string) {
                const params = new URLSearchParams();
                params.append("id", id.toString());
                params.append("eventCode", eventCode);
                try{
                    const r = await getNow(`Team/SignUpApi/GetById/`,params) as AxiosResponse<SignUpViewModel>;
                    return r.data;

                } catch (error) {
                    throw(prettyResponse(error,undefined));
                }              
            },

            async getSignUpEvent (eventCode: string) {
                const params = new URLSearchParams();
                params.append("eventCode", eventCode);
                try{
                    const r = await getNow(`Team/SignUpApi/GetSignUpEvent/`,params) as AxiosResponse<SignUpEventDto>;
                    return r.data;

                } catch (error) {
                    throw(prettyResponse(error,undefined));
                }              
            },

            async getAllSignUps (eventCode: string) {
                const params = new URLSearchParams();
                params.append("eventCode", eventCode);
                try{
                    const r = await getNow(`Team/SignUpApi/GetAllSignUps/`,params) as AxiosResponse<SignUpListItem[]>;
                    return r.data;

                } catch (error) {
                    throw(prettyResponse(error,undefined));
                }              
            },

            async getAllOpenSignUpEvents () {
                try{
                    const r = await getNow(`Team/SignUpApi/GetOpenSignUpEvents/`,undefined) as AxiosResponse<SignUpEventDto[]>;
                    return r.data;

                } catch (error) {
                    throw(prettyResponse(error,undefined));
                }              
            },

            async getInvoicePdf (id: number, eventCode: string) {
                const auth = useAuthStore();                
                const params = new URLSearchParams();
                params.append("id", id.toString());
                params.append("eventCode", eventCode);
                
                try {        
                  const res = await axios({
                    url: getDomain() + '/Team/SignUpApi/GetInvoice/',
                    params: params,
                    method: 'GET',
                    responseType: 'arraybuffer',
                    headers: { Authorization: 'Bearer ' + auth.authToken,
                    Locale: auth.locale ?? "en",
                    "Accept-Language": auth.locale ?? "en"},
                  });
                  return new Blob([res.data]);
                } catch (err) {
                    throw(prettyResponse(err,undefined));
                }                  
            }
        }
  // other options...
})