
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
loadingController,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { logInOutline} from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "ResetWithToken",
  data() {
    return {
      loginInfo: { password: "", password2: "" }
    };
  },
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    const {showError} = useErrorBox();
    const token: Ref<string> = ref("");
    const userName: Ref<string> = ref("");

    const { t, n } = useI18n<{ message: MessageSchema; number: NumberSchema }>({
      useScope: "global",
    });
    
    const loading = async () => {
      const loading = await loadingController.create({
        message: t("processing"),
      });

      await loading.present();
      return loading;
    };
    
    onMounted(() => {
      const parameters = router.currentRoute.value.query;
      if(parameters.token){
        token.value = parameters.token.toString();
      }
      if(parameters.user){
        userName.value = parameters.user.toString();
      }
    });

    const resetNow = async (p: any) => {
      if(p.password != p.password2){
        showError(t("setPassword.checkPasswdErr"), t("global.Error"));
        return;
      }
      if(p.password.length < 6 ){
        showError(t("setPassword.passwdShortErr"), t("global.Error"));
        return;
      }
      if(token.value.length < 6 ){
        showError(t("setPassword.tokenIsRequiredErr"), t("global.Error"));
        return;
      }

      const l = await loading();
      try {
        await auth.resetPasswordWithToken(token.value,userName.value,p.password);
        router.replace(`${process.env.VUE_APP_HOME_URL}`);
      } catch (err) {
        showError(err as string, t("global.Error"));
      } finally {
        l.dismiss();
      }
    };

    return {
      router,
      resetNow,
      logInOutline,
      token,userName,
      t,n,
    };
  },
});
